<template>
  <div>
    <b-card-code>
      <b-container fluid>
        <div :hidden="hidden">
          <div class="custom-search">
            <!-- advance search input -->
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <b-form-input
                    placeholder="Rechercher"
                    type="text"
                    class="d-inline-block"
                    @input="filterFunction"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <b-form-input
                    placeholder="Enregistrer du"
                    type="date"
                    class="d-inline-block"
                    @input="startDateFilterFunction"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <b-form-input
                    @input="endDateFilterFunction"
                    placeholder="Au"
                    type="date"
                    class="d-inline-block"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-table
            responsive
            :fields="fields"
            :items="factures"
            :filter="filter"
            :filter-function="dateFilter"
            size="lg"
            :tbody-tr-class="itemRowBackground"
          >
            <template #cell(vehicule)="data">
              <label for="">
                {{ data.item.vehicule.marque }} -
                {{ data.item.vehicule.numero_chassis }} - Année
                {{ data.item.vehicule.annee }}
              </label>
            </template>

            <template #cell(actions)="data">
              <b-button
                size="sm"
                variant="flat-primary"
                class="btn-icon"
                @click="showItem(data.item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </template>

            <template #cell(avance_percue)="data">
              {{ $thousandSeparator(data.item.avance_percue) }}
            </template>

            <template #cell(montant_paye)="data">
              {{ $thousandSeparator(data.item.montant_paye) }}
            </template>

            <template #cell(montant_vente)="data">
              {{ $thousandSeparator(data.item.montant_vente) }}
            </template>

            <template #cell(reste_a_paye)="data">
              {{ $thousandSeparator(data.item.reste_a_paye) }}
            </template>
          </b-table>
        </div>

        <div :hidden="hiddenEmpty" class="text-center text-secondary mt-2 mb-2">
          <empty-list></empty-list>

          <!-- <span>
						Aucune fonctionnalité disponible pour ce groupe utilisateur !
					</span> -->
        </div>
      </b-container>
    </b-card-code>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BBadge,
    BContainer,
    vSelect,
    BTable,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      filter: null,
      hidden: true,
      hiddenEmpty: true,
      required,
      pageLength: 10,
      dir: false,
      selected: [true],
      selectedModule: "",
      selectedModules: [],
      addFunctionModal: false,
      isOperating: false,
      factures: [],
      startDateFilterValue: null,
      endDateFilterValue: null,
      filterValue: null,
      fields: [
        {
          label: "Date",
          align: "left",
          sortable: false,
          key: "created_at",
        },
        { label: "Numero", key: "facture_no" },
        { label: "Client", key: "client.name" },
        { label: "Vehicule", key: "vehicule" },
        { label: "Prix Vente", key: "montant_vente" },
        { label: "Avance perçue", key: "avance_percue" },
        { label: "Montant payé", key: "montant_paye" },
        { label: "Reste à payer", key: "reste_a_paye" },
        // { label: 'Statut', key: 'statut', filter: this.statusFilter },
        { label: "Actions", align: "start", key: "actions", sortable: false },
      ],
      statusList: [
        { text: "Tout", value: null },
        { text: "Archivé", value: 1 },
        { text: "Non archivé", value: 0 },
      ],

      statusFilterValue: null,

      groupItem: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
  },
  created() {
    this.getFactures();
  },
  methods: {
    getFactures() {
      this.$http.get(`/archive/facture`).then((result) => {
        result.data.data.forEach((element) => {
          if (element.archive) element.statut = 1;
          else {
            element.statut = 0;
          }
        });
        this.factures = result.data.data;
        if (this.factures.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },

    startDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.startDateFilterValue = val;
    },

    endDateFilterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.endDateFilterValue = val;
    },

    filterFunction(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }
      this.filterValue = val;
    },

    statusFilter(value) {
      if (!this.statusFilterValue) {
        return true;
      }
      return value === this.statusFilterValue;
    },
    itemRowBackground: function (item) {
      return item.archive ? "archive" : "normal";
    },
    dateFilter(row, DateFilter) {
      var start = null;
      var end = null;
      if (this.startDateFilterValue) {
        var parts = this.startDateFilterValue.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      if (this.endDateFilterValue) {
        var parts = this.endDateFilterValue.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      var value = new Date(this.toDate(row.created_at));
      if (this.filterValue == "" || this.filterValue == null) {
        if (!start && !end) {
          return true;
        } else if (start && !end) {
          return value >= start;
        } else if (!start && end) {
          return value <= end;
        }
        return value >= start && value <= end;
      } else {
        if (
          row.client.name
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.montant_vente
            .toString()
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.facture_no
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.vehicule.marque
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.vehicule.numero_chassis
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1 ||
          row.vehicule.annee
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) != -1
        ) {
          if (!start && !end) {
            return true;
          } else if (start && !end) {
            return value >= start;
          } else if (!start && end) {
            return value <= end;
          }
          return value >= start && value <= end;
        } else {
          return false;
        }
      }
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
      // return this.reformatDate(date)
    },

    showItem(item) {
      let routeData = this.$router.resolve({
        name: "facture-show",
        params: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" >
.archive {
  border-left: 15px solid #ffb74d !important;
}

.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
